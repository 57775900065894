<template>
  <body-card  :show="loading">
    <template v-slot:body>
      <b-row>
        <b-col lg="12" sm="12">
          <b-row>
            <b-col sm="3">
            </b-col>
            <b-col sm="6">
              <u><b><p style="font-size: 20px;text decoration:underline;" class="mb-2">{{ $t('externalTraining.evaluation_resource_person') }}</p></b></u>
            </b-col>
            <b-col sm="3">
            </b-col>
          </b-row>
          <b-table-simple borderless>
            <b-tr>
              <b-th class="py-0" style="padding-left: 50px;">{{ $t('elearning_config.training_title') }}</b-th>
              <b-th class="py-0">:</b-th>
              <b-th class="py-0">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="py-0" style="padding-left: 50px;">{{ $t('elearning_config.organization') }}</b-th>
              <b-th class="py-0">:</b-th>
              <b-th class="py-0">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</b-th>
            </b-tr>
            <b-tr>
              <b-th class="py-0" style="padding-left: 50px;">{{ $t('globalTrans.date') }}</b-th>
              <b-th class="py-0">:</b-th>
              <b-th class="py-0">{{ date }}</b-th>
            </b-tr>
          </b-table-simple>
          <b-row>
            <b-col sm="12">
              <b><p style="font-size: 20px;">{{ $t('externalTraining.methodology_evaluation') }}</p></b>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <p style="font-size: 20px;" v-if="$i18n.locale === 'en'">{{ $t('externalTraining.methodology_evaluation_details_p1') }} {{ $n(formData.trainer_evaluation_marks.length * 5) }} {{ $t('externalTraining.methodology_evaluation_details_p2') }} {{ $n(formData.trainer_evaluation_marks.length) }} {{ $t('externalTraining.methodology_evaluation_details_p3') }}</p>
              <p style="font-size: 20px;" v-if="$i18n.locale === 'bn'">{{ $t('externalTraining.methodology_evaluation_details_p1') }} {{ $n(formData.trainer_evaluation_marks.length) }} {{ $t('externalTraining.methodology_evaluation_details_p2') }} {{ $n(formData.trainer_evaluation_marks.length * 5) }} {{ $t('externalTraining.methodology_evaluation_details_p3') }}</p>
            </b-col>
          </b-row>
          <b-row>
            <div class="col-md-12" id="form">
              <fieldset class="p-2 w-100">
                <b-overlay :show="loading">
                  <b-table-simple bordered v-if="dates.length > 0">
                    <b-thead class="thead">
                      <b-tr>
                        <b-th class="text-center">{{$t('elearning_config.trainer_evaluation_name')}}</b-th>
                        <b-th>{{$n(5)}}</b-th>
                        <b-th>{{$n(4)}}</b-th>
                        <b-th>{{$n(3)}}</b-th>
                        <b-th>{{$n(2)}}</b-th>
                        <b-th>{{$n(1)}}</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tr v-for="(item,index) in formData.trainer_evaluation_marks" :key="index">
                      <b-td class="text-center">
                        {{ $i18n.locale === 'bn' ? item.evaluation_name_bn : item.evaluation_name }}
                      </b-td>
                      <!-- <b-td v-for="(dateData, dateIndex) in dates" :key="dateIndex">
                        <span v-if="dateCheckEvaluation(item.trainer_evaluation_detail_id, dateData, item) === 5"><i class="ri-check-fill"></i></span>
                        <span v-else></span>
                        {{ ($i18n.locale=='en') ? $store.state.ExternalUserService.trainingPanel.commonObj.gradingStatus.find(gradingStatus => gradingStatus.value === parseInt(item.grading_status_id)).text_en : $store.state.ExternalUserService.trainingPanel.commonObj.gradingStatus.find(grading => grading.value === parseInt(item.grading_status_id)).text_en }}
                        {{ data.attain_marks }}
                      </b-td> -->
                      <b-td>
                        <span v-if="item.attain_marks === 5"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="item.attain_marks === 4"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="item.attain_marks === 3"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="item.attain_marks === 2"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                      <b-td>
                        <span v-if="item.attain_marks === 1"><i class="text-success fa fontawesome">&#xf00c;</i></span>
                        <span v-else></span>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </b-overlay>
              </fieldset>
            </div>
          </b-row>
          <b-row>
            <b-col sm="9" class="pt-2"></b-col>
            <b-col sm="3" class="pb-2">{{ $t('globalTrans.total') }} : {{ $n(formData.total_marks, { minimumFractionDigits: 2 }) }}</b-col>
          </b-row>
          <!-- <b-row>
            <b-col sm="12">
              <p style="font-size: 20px;">{{ $t('externalTraining.methodology_evaluation_details_p4') }}</p>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </template>
  </body-card>
</template>
<script>
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl, seedFertilizerServiceBaseUrl } from '@/config/api_config'
// import ExportPdf from './export_pdf_details'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.evaluationData()
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      date: '',
      loading: false,
      datas: [],
      marks: [],
      circularData: [],
      dates: [],
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      trainingTitleList: []
    }
  },
  methods: {
    dateCheckEvaluation (trainerEvaluationDetailId, dateData, item) {
      const evaluationMark = this.datas.find(data => data.training_date === dateData)
      if (typeof evaluationMark !== 'undefined') {
        const mark = evaluationMark.trainer_evaluation_marks.find(evaluationMark => evaluationMark.trainer_evaluation_detail_id === trainerEvaluationDetailId)
        // const grdObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.gradingStatus.find(gradingStatus => gradingStatus.value === parseInt(mark.grading_status_id))
        // if (typeof grdObj !== 'undefined') {
        //   return grdObj.average_number
        // }
        if (typeof mark !== 'undefined') {
          return mark.attain_marks
        }
      }
      return item
      // courseData.obtained_mark = typeof mark !== 'undefined' ? mark.obtained_mark : 0
    },
    calculateTotal () {
        this.formData.total_marks = this.marks.reduce((a, b) => a + parseFloat(b.attain_marks), 0)
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async evaluationData () {
      this.loading = true
      const params = {
        circularMemoNo: this.formData.circular_memo_no,
        trainerId: this.formData.trainer_id,
        batchId: this.formData.batch_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/trainer-evaluation/details', params)
      if (!result.success) {
        this.datas = []
      } else {
        this.datas = result.data
        this.marks = result.data[0].trainer_evaluation_marks
        this.circularData = result.circularPublication
        const startDate = this.circularData.training_start_date.split('-')
        const endDate = this.circularData.training_end_date.split('-')
        let startMonthId = ''
        let endMonthId = ''
        startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
        endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
        if (startDate[1] === endDate[1]) {
          this.date = this.$n(startDate[2]) + (this.currentLocale === 'en' ? ' to ' : ' হতে ') + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
        } else {
          this.date = this.$n(startDate[2]) + ' - ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + (this.currentLocale === 'en' ? ' to ' : ' হতে ') + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
        }
        result.data.map(item => {
          this.dates.push(item.training_date)
        })
        this.calculateTotal()
      }
      this.loading = false
    },
    // pdfExport () {
    //   const reportTitle = this.$t('externalTraining.trainer_evaluation') + this.$t('globalTrans.details')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this, this.datas, this.dates)
    // }
    async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.formData.org_id, app_id: this.id, trainerId: this.formData.trainer_id, circularMemoNo: this.formData.circular_memo_no, batchId: this.formData.batch_id })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, 'external-user/trainer-evaluation/details', params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
