<template>
    <div class="inner-section">
        <card>
           <template v-slot:searchHeaderTitle>
              <h4 class="card-title">{{ $t('externalTraining.trainer_evaluation') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:searchBody>
              <b-row>
                <b-col sm="5">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.circular_memo_no"
                      :options="circularList"
                      id="circular_memo_no"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button size="sm" variant="primary" @click="searchData">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('externalTraining.trainer_evaluation') }} {{ $t('globalTrans.list') }}</h4>
            </template>
            <template v-slot:headerAction>
                <router-link to="trainer-evaluation-form" class="btn-add"><i class="ri-add-fill"></i>{{  $t('globalTrans.add_new') }}</router-link>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                  <select-column-check :labelData="labelData" :search="search" :columns="columns" />
                    <b-row>
                      <b-col md="12" class="table-responsive">
                        <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                          <template v-slot:cell(serial)="data">
                          {{ $n(data.item.serial + pagination.slOffset) }}
                          </template>
                          <template v-slot:cell(type_name)="data">
                          <span class="capitalize">{{ data.item.type_name }}</span>
                          </template>
                          <!-- <template v-slot:cell(training_date)="data">
                            <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                          </template> -->
                          <template v-slot:cell(batch_id)="data">
                            <span class="capitalize">{{ getBatch(data.item.batch_id) }}</span>
                          </template>
                          <template v-slot:cell(trainer_id)="data">
                            <span class="capitalize">{{ getTrainerName(data.item.trainer_id) }}</span>
                          </template>
                          <template v-slot:cell(status)="data">
                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                              <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                          </template>
                          <template v-slot:cell(view)="data">
                            <!-- <b-button v-b-modal.modal-4 variant="btn-sm" size="sm" @click="edit(data.item)">
                                <i class="fas fa-eye"></i>
                              </b-button> -->
                              <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                          </template>
                          <template v-slot:cell(action)="data">
                              <b-button variant="iq-bg-info" size="sm" @click="remove(data.item)" ><i class="far fa-check-square m-0"></i></b-button>
                              <!-- <router-link title="Edit" :to="`/trainee-panel/trainer-evaluation-form?id=${data.item.id}`" class=" btn btn-sm"><i class="ri-ball-pen-fill m-0"></i></router-link> -->
                          </template>
                        </b-table>
                        <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" hide-footer :title="$t('externalTraining.trainer_evaluation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerEvaluationList, trainerEvaluationToggleStatus, getTraineeCircular } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        circularLoading: false,
        search: {
          circular_memo_no: 0,
          limit: 20
        },
        circularList: [],
        ids: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        allData: [],
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
          { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
          { labels: 'externalTraining.batch', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
          { labels: 'externalTraining.trainer_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
        ]
      }
    },
    computed: {
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
            return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year_bn' },
            { key: 'circular_memo_no' },
            { key: 'org_bn' },
            { key: 'training_title_bn' },
            { key: 'batch_id' },
            { key: 'trainer_id' },
            { key: 'view' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year' },
            { key: 'circular_memo_no' },
            { key: 'org' },
            { key: 'training_title' },
            { key: 'batch_id' },
            { key: 'trainer_id' },
            { key: 'view' }
          ]
        }

        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainerEvaluationList
      }
    },
    async created () {
      this.loadData()
      this.getCircularList()
      this.getTrainerDataList()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
     mounted () {
    },
    methods: {
      getBatch (id) {
        const batch = this.$store.state.TrainingElearning.commonObj.batchList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return batch !== undefined ? batch.text_bn : ''
        } else {
          return batch !== undefined ? batch.text_en : ''
        }
    },
    getTrainerDataList () {
      this.loader = true
      RestApi.getData(trainingElearningServiceBaseUrl, 'tim/trainer-evaluations/trainer-details').then(response => {
        this.loader = false
        if (response.success) {
          this.ids = response.data.map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
          })
        } else {
          this.ids = []
        }
      })
    },
    getTrainerName (id) {
      const dataStallCategory = this.ids.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return dataStallCategory !== undefined ? dataStallCategory.text_bn : ''
      } else {
        return dataStallCategory !== undefined ? dataStallCategory.text_en : ''
      }
    },
      async getCircularList () {
        this.circularLoading = true
        const params = {
          trainee_id: this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, getTraineeCircular, params)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, trainerEvaluationToggleStatus, item)
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, trainerEvaluationList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data, this.search.limit)
            this.allData = response.data.data
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          // const trainerObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.personalInfoList.find(doc => doc.value === parseInt(item.trainer_id))
          // const trainerData = {}
          // if (typeof trainerObj !== 'undefined') {
          //   trainerData.trainer_name = trainerObj.text_en
          //   trainerData.trainer_name_bn = trainerObj.text_bn
          // } else {
          //   trainerData.trainer_name = ''
          //   trainerData.trainer_name_bn = ''
          // }
          item.trainer_evaluation_marks = item.trainer_evaluation_marks.map(trainerEvaluationMark => {
            const trainerEvaluation = this.trainerEvaluationList.find(trainerEvaluationObj => trainerEvaluationObj.value === parseInt(trainerEvaluationMark.trainer_evaluation_detail_id))
            const customItem = {
              evaluation_name: trainerEvaluation?.text_en,
              evaluation_name_bn: trainerEvaluation?.text_bn
            }
            return Object.assign({}, trainerEvaluationMark, customItem)
          })
          return Object.assign({}, item, { serial: index }, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
